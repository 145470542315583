import "next/dist/client/app-webpack";
import { appBootstrap } from "next/dist/client/app-bootstrap";
import "wujie/esm/iframe";
import { bridgeEvent } from "@/entry/event";

const load = function () {
  appBootstrap(() => {
    const { hydrate } = require("next/dist/client/app-index");
    require("next/dist/client/components/app-router");
    require("next/dist/client/components/layout-router");
    if (!window.__POWERED_BY_WUJIE__) {
      hydrate();
    } else {
      window.__WUJIE_MOUNT = function () {
        void hydrate();
      };
      window.__WUJIE_UNMOUNT = function () {};
      bridgeEvent(window.$wujie.shadowRoot);
      window.__WUJIE_MOUNT();
      setTimeout(() => {
        // hack
        document.getElementsByTagName("body")[0]!.click();
      }, 1000);
    }
  });
};
if (!window.__POWERED_BY_WUJIE__) {
  load();
} else {
  // window.addEventListener("load", load);
  const interval = setInterval(() => {
    // @ts-ignore
    if (window.__next_f && window.__next_f.length >= 5) {
      clearInterval(interval);
      load();
    }
  }, 200);
}
